import type { ProductForCustomer } from "../Product.js";
import type { ProductSize } from "../ProductSize.js";
import { getDefaultSizeForProductForCustomer } from "./getDefaultSizeForProductForCustomer.js";

export function getDefaultSizeForProductForCustomerWithExpress(
	product: ProductForCustomer,
	isWarehouseProduct: boolean,
): ProductSize {
	const defaultSize = getDefaultSizeForProductForCustomer(product);
	if (isWarehouseProduct && product.warehouseCounts && product.warehouseCounts[defaultSize] === undefined) {
		const availableSize = Object.keys(product.warehouseCounts)[0] as ProductSize | undefined;
		if (availableSize === undefined) {
			throw new Error(`Product has no available size for warehouse product.`);
		}
		return availableSize;
	}

	return defaultSize;
}
