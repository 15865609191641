<script lang="ts">
	import { CustomerType } from "../../../../core/schema/CustomerType";
	import type { ProductForCustomer } from "../../../../core/schema/Product";
	import { getNearestDeliveryDateForProduct } from "../../../../core/schema/utils/getNearestDeliveryDate";
	import { toDateMonthFormat } from "../../../../core/utils/toDateMonthFormat";
	import { toTimeFormat } from "../../../../core/utils/toTimeFormat";
	import { getGlobalSettings } from "../../getGlobalSettings";
	import Button from "../Button.svelte";
	import Message from "../Message.svelte";
	import Modal from "../Modal.svelte";
	import { getCartStore } from "../../contexts/cartStoreContextKey.js";

	export let showExpressWarningModal: boolean;
	export let product: ProductForCustomer;
	export let addItem: () => void | Promise<void>;

	const { TENANT_LOCALE } = getGlobalSettings();

	const cartStore = getCartStore();

	$: hasSoldOut = $cartStore.value.properties.isSoldOutExpress;

	$: nearestDeliveryDate = getNearestDeliveryDateForProduct(product, CustomerType.B2c, false);
</script>

<Modal bind:show={showExpressWarningModal} class="max-w-[35rem] !p-4 sm:!p-8">
	<div class="mx-auto max-w-lg text-center">
		<strong class="text-md">Tuto objednávku do zítra nezvládneme</strong>
		<p class="mt-2">
			{#if hasSoldOut}
				Expresní produkt lze objednat pouze na zítra. Vybraný produkt <b>{product.name[TENANT_LOCALE]}</b> však do zítra
				nestihneme připravit.
			{:else}
				Přidáním tohoto produktu posouváte nejbližší možné datum vyzvednutí
				{#if nearestDeliveryDate}
					na {toDateMonthFormat(nearestDeliveryDate, TENANT_LOCALE)} od {toTimeFormat(
						nearestDeliveryDate,
						TENANT_LOCALE,
					)}.
				{/if}
			{/if}
		</p>
	</div>
	<Message variant="express" class="mx-auto mt-4 max-w-md text-center">
		Pokud potřebujete jednu část objednávky
		<strong class="font-semibold">už na zítra</strong>
		a druhou
		<strong class="font-semibold">na později</strong>, udělejte
		<strong class="font-semibold">2 samostatné objednávky</strong>.
	</Message>

	<div slot="actions" class="flex flex-row-reverse flex-wrap items-center justify-center gap-2 md:gap-4">
		<Button
			variant="primary"
			on:click={() => {
				showExpressWarningModal = false;
			}}
		>
			Objednat na zítra, co jde
		</Button>
		{#if hasSoldOut}
			<Button
				variant="secondary"
				on:click={async () => {
					await cartStore.removeSoldOutProducts();
					await addItem();
				}}
			>
				Odebrat expresní produkty
			</Button>
		{:else}
			<Button variant="secondary" on:click={addItem}>Dám si všechno, ale později</Button>
		{/if}
	</div>
</Modal>
