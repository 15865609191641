import { Locale } from "../schema/Locale.js";

const dateMonthFormat: Record<Locale, Intl.DateTimeFormat> = {
	[Locale.cs]: new Intl.DateTimeFormat("cs-CZ", {
		day: "numeric",
		month: "short",
		timeZone: "Europe/Prague",
	}),
};

export function toDateMonthFormat(date: number | Date | string, locale: Locale): string {
	return dateMonthFormat[locale].format(typeof date === "string" ? new Date(date) : date);
}
