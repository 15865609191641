<script lang="ts">
	import { PriorityLabel } from "../../../../core/schema/PriorityLabel.js";
	import { priorityLabelNameMapWithExpress } from "../../../../core/schema/names/priorityLabelNameMap.js";
	import { getGlobalSettings } from "../../getGlobalSettings.js";

	export let label: PriorityLabel | "express";
	export let withoutBackground = false;
	let customClass = "";
	export { customClass as class };

	const { TENANT_LOCALE } = getGlobalSettings();
</script>

<span
	class="rounded uppercase leading-5 {customClass}"
	class:bg-white={!withoutBackground}
	class:px-2={!withoutBackground}
	class:py-0.5={!withoutBackground}
	class:text-primary={label === PriorityLabel.Favorite}
	class:text-tertiary={label === PriorityLabel.New}
	class:text-express={label === "express"}
>
	{priorityLabelNameMapWithExpress[label][TENANT_LOCALE]}
</span>
