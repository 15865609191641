import type { CustomerType } from "../CustomerType.js";
import type { ProductForCustomer } from "../Product.js";
import { getNearestDeliveryDateForProduct } from "./getNearestDeliveryDate.js";

export function isProductCurrentlyPurchasable(
	product: ProductForCustomer,
	customerType: CustomerType,
	isWarehouseProduct: boolean,
): boolean {
	return (
		(!product.soldOut || isWarehouseProduct) &&
		getNearestDeliveryDateForProduct(product, customerType, false) !== undefined
	);
}
